<template>
  <div class="detail">
    <van-cell-group class="cell-group">
      <van-cell title="维修单号" class="cell status-cell">
        <template #default>
          <span style="line-height:36px">{{ repairDetail.serialNo }}</span>
          <span v-if="repairDetail.status == 2" class="status finish-icon">已维修</span>
          <span v-else class="status wait-icon">待处理</span>
        </template>
      </van-cell>
      <van-cell title="所属学校" class="cell" :value="school" />
      <van-cell title="维修原因" v-if="repairDetail.status == 2" class="cell" :value="repairDetail.repairType" />
      <van-cell title="维修说明" v-if="repairDetail.status == 2" class="cell" :label="repairDetail.repairDescription" />
      <van-cell title="故障教室" class="cell" :value="repairDetail.location" />
    </van-cell-group>
    <van-cell-group class="cell-group">
      <van-cell title="故障设备" class="cell">
        <template #label>
          <div v-for="item, index in repairDetail.devices" :key="index" class="device">{{ item.name }}({{ item.value }})
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group class="cell-group">
      <van-cell title="联系人" class="cell" :value="repairDetail.manager.name" />
      <van-cell title="联系电话" class="cell" :value="repairDetail.phone" />
      <van-cell title="故障描述" class="cell" :label="repairDetail.faultDescription" />
    </van-cell-group>
    <van-cell-group class="cell-group">
      <van-cell title="创建时间" class="cell" :value="repairDetail.createdAt" />
    </van-cell-group>
  </div>
</template>

<script>
import { getRepairDetail } from '../../http/repair/repair'
export default {
  data() {
    return {
      repairDetail: {
        serialNo:'',
        status:'',
        repairType:'',
        repairDescription:'',
        devices:[],
        manager:{},
        phone:'',
        faultDescription:'',
        createdAt:'',
      },
      school: '',
    }
  },
  methods: {
    async getRepairDetail(id) {
      const res = await getRepairDetail(id)
      this.repairDetail = res.data

    }
  },
  created() {
    this.getRepairDetail(this.$route.params.id)
    this.school = this.$store.state.school
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-cell-group {
  margin-top: 16px;
}

.cell {
  margin-top: 0;
}

.status-cell {
  /deep/ .van-cell__value {
    flex: 2;
    line-height: 36px;
  }

  /deep/ .van-cell__title {
    flex: 1;
  }
}

.status {
  display: inline-block;
  text-align: center;
  width: 60px;
  height: 24px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 25px;
  margin-left: 6px;
}


.finish-icon {
  background: #01D079;
  border-radius: 12px;
  color: #FFFFFF;
}

.wait-icon {
  background: rgba(250, 100, 0, 0.34);
  border-radius: 12px;
  color: #FA6400;
  border: 1px solid #FA6400;
}

.device {
  padding: 0 16px;
  margin-right: 13px;
  margin-bottom: 10px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 16px;
  border: 1px solid #378BF7;
  box-sizing: border-box;
  font-size: 14px;
  color: #378BF7;
  line-height: 32px;
  text-align: center;
  display: inline-block;
}

/deep/ .van-cell__label {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 24px;
}

/deep/ .van-cell__value {
  font-size: 17px;
}
</style>